// import barba from '@barba/core';
import gsap from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger.js";
// gsap.registerPlugin(ScrollTrigger);
// import lottie from "lottie-web";
// import LocomotiveScroll from 'locomotive-scroll';
// import { tns } from 'tiny-slider/src/tiny-slider';
// import lottie from "lottie-web";

// import PamCursor from './yeah/PamCursor.js';
// import ImageTrailInit from './yeah/image-trail.js';
// import OurLottie from './yeah/our-lottie.js';
// import Magnetic from './yeah/magnetic.js';


// fixed scrollbar
if(document.querySelector('body').scrollWidth>document.querySelector('body').offsetWidth) document.querySelector('html').classList.add('widthFixedScrollbar');





window.addEventListener("DOMContentLoaded", function() {
	// if(document.querySelector('body[local]')) {
	// 	document.addEventListener('keypress', logKey);
	// }

	// iOS — status bar gate — pour avoir du pseudo 100vh
	function appHeight() { document.documentElement.style.setProperty('--app-height', window.innerHeight+'px') }
	document.documentElement.style.setProperty('--app-height-init', window.innerHeight+'px')
	window.addEventListener('resize', appHeight)
	appHeight()



	/* */
	document.getElementById('booking').addEventListener('click', function() {
		if(document.querySelector('.booking__popup').classList.contains('active')) document.querySelector('.booking__popup').classList.remove('active');
		document.querySelector('.booking__popup').classList.add('active');
	});

	document.getElementById('booking__close').addEventListener('click', function() {
		document.querySelector('.booking__popup').classList.remove('active');
	});


	/*
	if(window.innerWidth>=1200) {
		window.addEventListener('scroll', function(e) {
			if (!ticking) window.requestAnimationFrame(function() { doSomething(window.scrollY); ticking = false; });
			ticking = true;
		});
		doSomething(0)
	}
	*/

	// gsap.ticker.add(allTheTime);
	gsap.delayedCall(10, slider)
});


var i = 0, slideNext;
var colors = new Array('red', 'green', 'orange');
var action = gsap.timeline();

function slider() {
	if(document.querySelector('.home__slider')) {
		
		var slides = document.querySelectorAll('.slider__item'),
		slideActive = document.querySelector('.slider__item.active'),
		container = document.querySelector('.home__container')

		if ( container.classList.contains(colors[i])) container.classList.remove(colors[i]);


		if(i == (slides.length - 1)) {
			slideNext = slides[0];
			container.classList.add(colors[0]);
		}
		else {
			slideNext = slides[i+1];
			container.classList.add(colors[i+1]);
		}

		action.fromTo(slideNext, {opacity: 0}, {duration: 0.8, opacity: 1, ease:'linear', onComplete: function() {
			slideNext.classList.add('active');
			slideActive.classList.remove('active');
			gsap.set(slideActive, {opacity: 0});

		}});
		

		if(i == (slides.length - 1)) i = 0
		else i++;
		gsap.delayedCall(10, slider);
	}
}


/*
var observer = new IntersectionObserver(function(entries) {
    entries.map((entry) => { // The callback will return an array of entries, even if you are only observing a single item
        if(entry.isIntersecting) entry.target.classList.add('on')
        else entry.target.classList.remove('on')
    });
});
observer.observe(document.querySelector('#monDiv'));
*/




function doSomething(scrollPosition) {
	Array.prototype.forEach.call(document.querySelectorAll('[data-speed],[pamAppear]'), function(el, i){

		// px
		if(el.getAttribute('data-speed')!=null) {
			if(el.getBoundingClientRect().top<window.innerHeight && el.getBoundingClientRect().bottom>0) {
				if(!el.hasAttribute('data-start')) el.setAttribute('data-start',el.getBoundingClientRect().top + window.pageYOffset);
				var valTemp = Math.round(window.pageYOffset - el.getAttribute('data-start') + el.getBoundingClientRect().height/2)*el.getAttribute('data-speed')/10
				if(el.getAttribute('data-offset')!=null) valTemp = parseInt(valTemp)+parseInt(el.getAttribute('data-offset'));
				if(el.getAttribute('horizontal')==null) el.style.transform = "translate3d(0,"+valTemp+"px,0)";
				else el.style.transform = "translate3d("+valTemp+"px,0,0)";
			}
		}

		// inview
		if(el.getAttribute('pamAppear')!=null) {
			if(el.getBoundingClientRect().top<window.innerHeight && el.getBoundingClientRect().bottom>0 && !el.classList.contains('on')) el.classList.add('on')
			if(el.getBoundingClientRect().top>window.innerHeight && el.classList.contains('on')) el.classList.remove('on')
		}

		if(document.querySelector('.downloadsHead').getBoundingClientRect().top<window.innerHeight/2) navFocus('downloads')
		else if(document.querySelector('.bigPhotoTextBlock--driving').getBoundingClientRect().top<window.innerHeight/2) navFocus('innovation')
		else if(document.querySelector('.bigPhotoTextBlock--range').getBoundingClientRect().top<window.innerHeight/2) navFocus('fuelcell')
		else if(document.querySelector('.whyHydrogen').getBoundingClientRect().top<window.innerHeight/2) navFocus('hydrogen')
		else navFocus('welcome')

		if(window.pageYOffset>window.innerHeight/2) document.querySelector('.header').classList.add('bg');
		else document.querySelector('.header').classList.remove('bg');
	});
}


function logKey(e) {
	if(e.code=='Space') {
		e.preventDefault();
		if(document.querySelector('body').classList.contains('dev')) document.querySelector('body').classList.remove('dev');
		else document.querySelector('body').classList.add('dev');
	}
}



function indexInParent(node) {
    var children = node.parentNode.childNodes;
    var num = 0;
    for (var i=0; i<children.length; i++) {
         if (children[i]==node) return num;
         if (children[i].nodeType==1) num++;
    }
    return -1;
}

function isDark(color) {
	if (color.match(/^rgb/)) { // Check the format of the color, HEX or RGB?
    	color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/); // If HEX --> store the red, green, blue values in separate variables
    	r = color[1]; g = color[2]; b = color[3];
  } else {
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&' ));
    r = color >> 16; g = color >> 8 & 255; b = color & 255; }
    hsp = Math.sqrt( 0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    if (hsp>127.5) return false;
    else return true
}



function mailOK(email) {
	var reg = new RegExp('^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$', 'i');
	if(reg.test(email)) return(true);
	else return(false);
}


function okornot() {
	if(document.querySelector('input[name="c_prenom"]').value=='') document.querySelector('input[name="c_prenom"]').style.borderBottom = '1px solid red';
	else document.querySelector('input[name="c_prenom"]').style.borderBottom = '1px solid #fff';

	if(document.querySelector('input[name="c_nom"]').value=='') document.querySelector('input[name="c_nom"]').style.borderBottom = '1px solid red';
	else document.querySelector('input[name="c_nom"]').style.borderBottom = '1px solid #fff';

	// if(mailOK(document.querySelector('input[name="c_email"]').value) == false) document.querySelector('input[name="c_email"]').style.borderBottom = '1px solid red'; else document.querySelector('input[name="c_email"]').style.borderBottom = '1px solid #fff';
	if(document.querySelector('input[name="c_tel"]').value=='') document.querySelector('input[name="c_tel"]').style.borderBottom = '1px solid red';
	else document.querySelector('input[name="c_tel"]').style.borderBottom = '1px solid #fff';

	if(document.querySelector('textarea[name="c_message"]').value=='') document.querySelector('textarea[name="c_message"]').style.borderBottom = '1px solid red';
	else document.querySelector('textarea[name="c_message"]').style.borderBottom = '1px solid #fff';
	
	if(document.querySelector('input[name="c_prenom"]').value!='' && document.querySelector('input[name="c_nom"]').value!='' && document.querySelector('input[name="c_tel"]').value!='') {
		return true;
	}
	return false;
}




function is_touch_device() {
	return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}



// marquee
/*
// dans rAF
gsap.set(document.querySelector('.agencyTop__title-2'), {x:'-=20px'})
if(gsap.getProperty('.agencyTop__title-2', 'x')<-1*(document.querySelector('.agencyTop__title-2').scrollWidth/2)) gsap.set(document.querySelector('.agencyTop__title-2'), {x:'0px'})
*/
/*
// timeout
gsap.delayedCall(0.5, function() {})
*/
/*
// scrolltrigger
gsap.fromTo(w, {  x  }, {
  x: xEnd,
  scrollTrigger: { 
    trigger: document.querySelector('.talentsTop__filigrane'), 
    scrub: 0.5 
  }
});
*/


/*

// https://codepen.io/GreenSock/pen/eYpOZvX

gsap.registerPlugin(ScrollTrigger);

gsap.set(".massiveImage", {backgroundImage: `url(https://source.unsplash.com/random/${innerWidth * 3}x${innerHeight})`})

gsap.to(".massiveImage", {
  xPercent: -100, 
  x: innerWidth,
  ease: "none",
  scrollTrigger: {
    trigger: ".massiveImage",
    start: "top top",
    end: () => innerWidth * 3,
    scrub: true,
    pin: true,
    anticipatePin: 1
  }
});

*/


// maps --> parseFloat(lat),parseFloat(lon)





/*
// iife
// loop avec events :-)

el_burger_a = document.querySelectorAll('a.hover_img');
for(var i = 0; i<el_burger_a.length; i++) {
	(function(index) {
		el_burger_a[i].addEventListener('mouseenter', function() { mouseenter_burger(index); });
	})(i);
}
*/








